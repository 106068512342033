body {
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
    margin: 0;
    padding: 0;
}

.token-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.one-to-watch-container {
    width: 100%;
    max-width: 1200px;
    background: gold;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.one-to-watch-container h2 {
    margin: 0 0 20px 0;
    color: #333;
}

.sidebar {
    width: 200px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
    text-align: center;
}

.sidebar h2 {
    margin: 0 0 20px 0;
    color: #333;
}

.sidebar-item {
    background: #f9f9f9;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.sidebar-item h4 {
    margin: 0;
    color: #555;
}

.sidebar-item p {
    margin: 5px 0 0 0;
    color: #777;
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 20px;
}

.tokens-section {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.tokens-section h2 {
    margin: 0 0 20px 0;
    color: #333;
}

.tokens-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.token-card {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 200px;
    text-align: center;
    transition: transform 0.2s;
}

.token-card:hover {
    transform: translateY(-10px);
}

.token-card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.token-card h3 {
    margin-bottom: 10px;
    color: #333;
}

.token-card p {
    margin: 5px 0;
    color: #666;
}

.token-card a {
    display: inline-block;
    margin-top: 10px;
    color: #1e90ff;
    text-decoration: none;
}

.token-card a:hover {
    text-decoration: underline;
}

.last-updated {
    margin-top: 20px;
    color: #777;
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
