.dm-free-tool-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 800px;
}

.dm-free-tool-section:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.dm-free-tool-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dm-free-tool-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    font-size: 1em;
}

.dm-free-tool-input:focus {
    border-color: #007bff;
}

.dm-free-tool-button {
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1em;
}

.dm-free-tool-button:hover {
    background: #0056b3;
}
