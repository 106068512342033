.task-meeting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 40px auto;
    padding: 30px;
    background: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    animation: fadeIn 1s ease-in-out;
}

.task-platform {
    margin-right: 20px;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.form-container {
    display: flex;
    justify-content: space-around;
    width: 92%;
    margin-bottom: 40px;
}

.task-form, .meeting-form {
    width: 45%;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input, .form-group select {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.form-group input:focus, .form-group select:focus {
    border-color: #DCA123;
    outline: none;
}

.button-group {
    display: flex;
    justify-content: center;
}

button {
    padding: 10px 20px;
    background: #DCA123;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

button:hover {
    background: #b5891d;
}

.list-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.task-list, .meeting-list {
    width: 45%;
}

.task-item, .meeting-item {
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
}

.task-item.expired {
    background: #ffcccc;
}

.task-item.due-soon {
    background: #fff3cd;
}

.task-item .button-group, .meeting-item .button-group {
    display: flex;
    justify-content: space-between;
}

.task-item button {
    padding: 10px 20px;
    background: #DCA123;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.task-item button:hover, .meeting-item button:hover {
    background: #b5891d;
}

.task-card, .meeting-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-details, .meeting-details {
    flex: 1;
    border-right: 1px solid;
}

.task-info, .meeting-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
}

.task-value, .meeting-platform, .task-deadline, .meeting-time {
    margin-right: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    text-align: center;
}

.meeting-time, .task-deadline {
    color: black;
}

.task-value {
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
}

.platform-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #ccc;
}

.contact-btn, .description-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.description-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 25px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.delete-btn {
    background: red;
    color: white;
    font-size: 0.8em;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    bottom: 10px;
    right: -10px;
}

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}
