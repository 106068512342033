.dm-tool-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
}

.dm-tool-section:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.dm-tool-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dm-tool-label {
    width: 100%;
    font-size: 1em;
    margin-bottom: 10px;
    color: #333;
}

.dm-tool-input, .dm-tool-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    font-size: 1em;
}

.dm-tool-input:focus, .dm-tool-select:focus {
    border-color: #007bff;
}

.dm-tool-button {
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1em;
}

.dm-tool-button:hover {
    background: #0056b3;
}

.dm-tool-section .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dm-tool-section .loading p {
    margin-top: 20px;
    font-size: 1.2em;
    color: #333;
}

.dm-tool-section .download {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.dm-tool-section .download h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #007bff;
}

.dm-tool-section .download p {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #333;
}

.dm-tool-section .download button {
    padding: 10px 20px;
    background: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1em;
}

.dm-tool-section .download button:hover {
    background: #218838;
}

.dm-tool-section .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.dm-tool-section .error h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #dc3545;
}

.dm-tool-section .error p {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #333;
}

.dm-tool-section .top-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.dm-tool-section .top-up h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #ffc107;
}

.dm-tool-section .top-up p {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #333;
}

.dm-tool-section .top-up-failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.dm-tool-section .top-up-failed h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #dc3545;
}

.dm-tool-section .top-up-failed p {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #333;
}
