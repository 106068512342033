.contact-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.contact-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.contact-container p {
    font-size: 1.2em;
    margin-bottom: 20px;
    line-height: 1.6;
    color: #333;
}

.response-time {
    font-size: 0.9em;
    color: #666;
    margin-top: 20px;
}
