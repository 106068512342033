.category-page-head {
    text-align: center;
    padding: 40px 20px;
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 40px auto;
}

.tool-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}
