.footer {
    background: #343a40;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
}

.social-links {
    margin: 10px 0;
}

.social-links a {
    color: lightblue;
    margin: 0 10px;
    font-size: 1.5em;
}

.footer-links {
    margin-top: 10px;
}

.footer-links a {
    color: lightgrey;
    margin: 0 5px;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}
