.header {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 40px;
    margin-right: 10px;
}

.user-info {
    display: flex;
    align-items: center;
}

.credit-box {
    display: flex;
    align-items: center;
    background: #f1f1f1;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 10px;
}

.credit-image {
    height: 20px;
    margin-right: 5px;
}

.user-role {
    background: #007bff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: bold;
}

.burger-menu {
    display: none;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.header-nav {
    background: #343a40;
}

.nav-list {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.nav-list li {
    margin: 0 15px;
}

.nav-list a {
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
}

.nav-list a:hover,
.nav-list .active a {
    background: #495057;
    border-radius: 4px;
}

.dropdown {
    position: relative;
    align-content: center;
}

.dropdown-toggle {
    cursor: pointer;
    color: #fff;
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #343a40;
    padding: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu li {
    margin: 0;
}

.dropdown-menu a {
    padding: 10px 20px;
    white-space: nowrap;
}

.header-nav.open {
    display: block;
}

@media (max-width: 768px) {
    .burger-menu {
        display: block;
    }

    .header-nav {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: #343a40;
        text-align: center;
    }

    .nav-list {
        flex-direction: column;
    }

    .nav-list li {
        margin: 10px 0;
    }

    .header-nav.open {
        display: block;
    }
}
