.sidebar {
    width: 200px;
    display: flex;
    flex-direction: column;
    background: #007bff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar button {
    background: #fff;
    color: #007bff;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
}

.sidebar button:hover {
    background: #0056b3;
    color: #fff;
}
