.about-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
}

.about-header {
    text-align: center;
    margin-bottom: 40px;
}

.about-header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.about-header p {
    font-size: 1.2em;
    line-height: 1.6;
}

.mission-vision {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
}

.mission-vision .mission, .mission-vision .vision {
    flex: 1;
    min-width: 300px;
    margin: 10px;
}

.mission-vision h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.mission-vision p {
    font-size: 1.1em;
    line-height: 1.6;
}

.history {
    margin-bottom: 40px;
}

.history h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.history p {
    font-size: 1.1em;
    line-height: 1.6;
}

.services {
    margin-bottom: 40px;
}

.services h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.services ul {
    list-style-type: disc;
    padding-left: 20px;
}

.services ul li {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 10px;
}

.suggestions {
    margin-bottom: 40px;
    text-align: center;
}

.suggestions h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.suggestions p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
}
