.user-invoice-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 40px auto;
    padding: 30px;
    background: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

li {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
}

.total {
    font-size: 1.5em;
    margin: 20px 0;
    font-weight: bold;
    color: #333;
}

.payment-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.payment-container > div {
    flex: 1;
    margin: 0 10px;
}
