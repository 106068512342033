.categories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

#categories a {
  text-decoration: none;
  color: inherit;
}
