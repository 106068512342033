body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style-type: none;
    padding: 0;
}

.header, .footer, .features-section, .interactive-banner {
    transition: all 0.3s ease;
}
