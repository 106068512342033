.category-item {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.category-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.category-title {
    margin-left: 10px;
    font-size: 1.2em;
    color: #333;
}
