@import url('./Core.css');

.home-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
}

.home-header {
    position: relative;
    text-align: center;
    color: #fff;
}

.welcome {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
}

.welcome h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #868686;
}

.welcome p {
    font-size: 1.2em;
}

.features-section {
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
}

.features-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.features-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.testimonials-section {
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
    padding: 40px 20px;
    background: #f9f9f9;
}

.testimonials-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.pricing-section {
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
    padding: 40px 20px;
    background: #f1f1f1;
}

.pricing-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.pricing-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 768px) {
    .pricing-card, .testimonial-card {
        min-width: calc(100% - 20px); /* Ensure cards take full width on small screens */
    }
}
