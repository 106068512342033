.recent-runs {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 1200px;
}

h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
}

.search-input {
    width: 80%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}

.pagination-controls {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pagination-controls select {
    margin-left: 5px;
    padding: 5px;
    font-size: 1em;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

th {
    background: #f4f4f4;
}

.btn {
    padding: 5px 10px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.btn-download {
    background: #28a745;
    color: #fff;
}

.btn-download:hover {
    background: #218838;
}

.btn-preview {
    background: #007bff;
    color: #fff;
    margin-left: 5px;
}

.btn-preview:hover {
    background: #0056b3;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.pagination button.active {
    background: #007bff;
    color: #fff;
}

.pagination button:hover {
    background: #0056b3;
    color: #fff;
}
