.cta-section {
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
    padding: 40px 20px;
    background: #007bff;
    color: #fff;
}

.cta-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.cta-section p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.cta-section button {
    background: #fff;
    color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.cta-section button:hover {
    background: #f1f1f1;
}
