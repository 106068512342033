.automated-browser-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: #fff3cd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    border: 2px solid #856404;
    color: #856404;
}

.warning-icon {
    font-size: 50px;
    margin-bottom: 20px;
    color: #856404;
}

.automated-browser-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.automated-browser-container p {
    font-size: 1.2em;
    margin-bottom: 20px;
    line-height: 1.6;
}

.automated-browser-container ul {
    text-align: left;
    margin-bottom: 20px;
    list-style-type: disc;
    padding-left: 40px;
}

.automated-browser-container ul li {
    font-size: 1.2em;
    margin-bottom: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
}

table, th, td {
    border: 1px solid #856404;
}

th, td {
    padding: 12px;
    text-align: left;
}

th {
    background-color: #ffeeba;
    color: #856404;
}

tbody tr:nth-child(even) {
    background-color: #fff8e1;
}

tbody tr:hover {
    background-color: #ffd966;
}
