/* Styles for the privacy policy container */
.privacy-container {
    max-width: 800px; /* Max width for the container */
    margin: 20px auto; /* Center the container */
    padding: 20px; /* Padding around the content */
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Styles for the title */
.privacy-title {
    font-size: 2em; /* Larger font for the title */
    margin-bottom: 10px; /* Space below the title */
    text-align: center; /* Center the title */
}

/* Styles for section headings */
h2 {
    margin-top: 20px; /* Space above section headings */
    color: #333; /* Darker color for headings */
}

/* Styles for paragraphs and lists */
p, ul {
    font-size: 1em; /* Normal font size for text */
    line-height: 1.5; /* Line height for readability */
    color: #555; /* Gray text color */
}

ul {
    margin-left: 20px; /* Indent lists */
}
