/* FCPayment.css */
.payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Full viewport height */
    background-color: #f7f9fc; /* Light background for modern look */
    padding: 20px;
    font-family: 'Arial', sans-serif; /* Modern font style */
}

.payment-title {
    font-size: 2.5rem; /* Larger title */
    margin-bottom: 20px;
    color: #333; /* Darker color for title */
    text-align: center; /* Center alignment */
}

.payment-info {
    margin-top: 20px;
    font-size: 1rem; /* Smaller font for info text */
    color: #666; /* Grey color for the info */
    text-align: center; /* Center alignment */
    max-width: 600px; /* Max width for better readability */
}
