#conrad-brothers {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f4f4f4;
  z-index: 10000;
}

#scroll-section {
  position: absolute;
  top: 0;
  width: 100%;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.grid-section {
  margin-bottom: 40px;
}

.grid-item {
  border: 2px solid;
  border-radius: 8px;
  padding: 15px;
  margin: 15px;
  min-width: calc(100% / 4 - 30px);
  max-width: calc(100% / 4 - 30px);
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.grid-item:hover {
  transform: scale(1.05);
}

.employeeName {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.employeeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 80px;
  height: 80px;
  border: 2px solid;
  text-align: center;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  background: white;
  font-weight: bolder;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
}

.employeeImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.timing {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.clockedIn {
  text-align: center;
  font-size: 14px;
  width: 50%;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 5px;
  margin: auto;
}

.workingFor {
  text-align: center;
  border: 1px;
  padding: 5px;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.overtime {
  background: rgb(243, 116, 116) !important;
  color: white !important;
}

.overtime-secondary {
  background: rgb(247, 76, 76) !important;
  color: white !important;
}

.grid-title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 20px;
  margin: auto;
}

.credit-scroll {
  position: absolute;
  font-size: 24px;
  animation: scroll-loop 60s linear infinite;
}

@keyframes scroll-loop {
  0% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(calc(-100% + 100vh));
  }
  50% {
    transform: translateY(calc(-100% + 100vh));
  }
  95% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
