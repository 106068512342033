.info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.info-block:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.infoBlockTitle {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

.infoBlockCurrency {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}
