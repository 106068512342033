.pricing-card {
    flex: 1;
    margin: 10px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    min-width: 280px; /* Ensure minimum width for better layout on smaller screens */
}

.pricing-card h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.pricing-card p {
    font-size: 1.2em;
    color: #007bff;
    margin-bottom: 20px;
}

.pricing-card ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.pricing-card ul li {
    font-size: 1em;
    color: #666;
    margin-bottom: 10px;
}

.pricing-card button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.pricing-card button:hover {
    background: #0056b3;
}
