.stripe-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    animation: fadeIn 1s ease-in-out;
}

.billing-details,
.card-details {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.billing-details input,
.card-details div {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
    font-size: 1em;
}

.billing-details input:focus,
.card-details div:focus {
    border-color: #DCA123;
    outline: none;
}

.pay-button {
    padding: 10px 20px;
    background: #DCA123;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1em;
    display: flex;
    align-items: center;
}

.pay-button i {
    margin-right: 5px;
}

.pay-button:hover {
    background: #b5891d;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
