.terms-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.terms-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.terms-container h2 {
    font-size: 1.8em;
    margin-top: 20px;
    margin-bottom: 10px;
}

.terms-container p {
    font-size: 1em;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
}
