.contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.form-group label {
    margin-bottom: 0.5rem;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

.form-group input:focus, .form-group textarea:focus {
    border-color: #007bff;
    outline: none;
}

textarea {
    min-height: 100px;
}

.consent {
    margin-bottom: 1.5rem;
    font-size: 0.9em;
}

.consent input {
    margin-right: 0.5rem;
}

.submit-button {
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1em;
}

.submit-button:hover {
    background: #0056b3;
}

.error-message {
    color: #ff0000;
    margin-top: 10px;
    font-size: 0.9em;
}

.message-sent {
    text-align: center;
    padding: 20px;
}

.message-sent h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.message-sent p {
    font-size: 1em;
    color: #666;
}
