.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-column, .right-column {
    flex: 1;
    margin: 10px;
}

.image-row, .form-row, .content {
    margin: 20px;
}

.image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.description-output {
    margin: 40px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.description {
    font-size: 1.2em;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
}

.output table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.output th, .output td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.output th {
    background: #f4f4f4;
}

.sources-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px auto;
}

.source-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.source-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.source-title {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.source-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

/* Add styling for tabs */
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tabs button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.tabs button.active {
    background: #0056b3;
}

.tabs button:hover {
    background: #0056b3;
}

.output {
    margin-top: 20px;
}

.output h4 {
    margin-bottom: 10px;
}

.output table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.output th, .output td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.output th {
    background-color: #f2f2f2;
    color: #333;
}

.output tr:nth-child(even) {
    background-color: #f9f9f9;
}

.output tr:hover {
    background-color: #f1f1f1;
}
