.collector-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
}

.collector-container h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5em;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input[type="text"],
.form-group select,
.form-group textarea,
.form-group input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

#modelOpen {
    font-size: 16px;
    margin-left: 10px;
    background: #DCA123;
    border: 1px solid white;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    transition: all .5s;
}

#modelOpen:hover {
    background: #f5ca6c;
    transition: all .5s;
}

#save-button,
#process-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    margin-right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

#save-button:hover,
#process-button:hover {
    background-color: #0056b3;
}

.status-area {
    margin-top: 20px;
    font-size: 1em;
    color: green;
}

#url-count {
    margin-top: 5px;
    font-size: 1em;
    color: #555;
}

.spreadsheet-notice {
    margin-top: 5px;
    font-size: 0.9em;
    color: #555;
    font-style: italic;
}

.modal {
    background-color: #000;
    background-color: #0006;
    display: block;
    height: 100%;
    left: 50%;
    overflow: auto;
    position: fixed;
    top: 50%;
    width: 100%;
    z-index: 1;
    transform: translate(-50%, -50%);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
