.stripe-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.amountInputDiv {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.amountInputDiv h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.amountInputDiv p {
    font-size: 1em;
    color: #666;
    margin-bottom: 10px;
}

.amountInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    font-size: 1em;
}

.amountInput:focus {
    border-color: #007bff;
}

.amountSelections {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.amountSelect {
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.amountSelect:hover {
    background: #0056b3;
}

.billing-details, .card-details {
    width: 100%;
    margin-bottom: 20px;
}

.billing-details input, .billing-details select, .card-details div {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    font-size: 1em;
}

.billing-details input:focus, .billing-details select:focus, .card-details div:focus {
    border-color: #007bff;
}

.pay-button {
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1em;
    display: flex;
    align-items: center;
}

.pay-button i {
    margin-right: 5px;
}

.pay-button:hover {
    background: #0056b3;
}
