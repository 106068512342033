.sources {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tools-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.toolArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.theTool {
    text-align: center;
    margin-top: 20px;
}

.back-button {
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    background: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s ease;
}

.back-button:hover {
    background: #0056b3;
}
